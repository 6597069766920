import { useEffect } from "react";

export function GoogleTagManager({ isProdEnv }: { isProdEnv: boolean }) {
  useEffect(() => {
    function initGTM() {
      console.info("Initializing gtm...");
      const dataLayerName = "dataLayer";

      if (!isProdEnv) {
        console.info("Google Tag Manager is only initialized in production and staging (test) environments.");
        return;
      }

      // set GTM ID based on environment
      // same tag for prod and test
      const gtmId = isProdEnv ? "GTM-N6R6J66G" : "";

      // init dataLayer
      (window as any)[dataLayerName] = (window as any)[dataLayerName] || [];

      // push isTestEnv prop
      // prettier-ignore
      // (window as any)[dataLayerName].push({
      //   "isTestEnv": isTestEnv
      // });

      // push gtm.js event
      // prettier-ignore
      (window as any)[dataLayerName].push({
        "gtm.start": new Date().getTime(),
        "event": "gtm.js"
      });

      const firstScriptTag = document.getElementsByTagName("script")[0];
      const newScriptTag = document.createElement("script");

      newScriptTag.async = true;
      newScriptTag.src = "https://www.googletagmanager.com/gtm.js?id=" + gtmId;

      if (firstScriptTag.parentNode) {
        // add script as first script if other scripts are present
        firstScriptTag.parentNode.insertBefore(newScriptTag, firstScriptTag);
      } else {
        // add script to head if no other scripts are present
        document.head.appendChild(newScriptTag);
      }
    }

    initGTM();
  }, []);

  return null;
}
